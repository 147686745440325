@import "variables";

.header-container {
  width: 100%;
  height: auto;
  position: fixed;
  z-index: 160000003;
  top: 0;
  opacity: 1;
  background-color: $main-white;
  transition: top .5s ease;

  &--white {
    background-color: $main-white;
    height: 80px;

    @include media-breakpoint-down(sm) {
      height: 64px;
    }

    .header {
      &__logo {
        &__image {
          background-size: 85%;
        }
      }

      &__basket {
        background: url('../images/icon_new_basket.svg') no-repeat center;
      }

      &__logged-in {
        &__icon {
          background: url('../images/icon-user.svg') no-repeat center;
        }
      }
    }
  }

  &--black {
    background-color: transparent;

    .header {
      @include media-breakpoint-down(sm) {
        background-color: transparent;
      }

      &__basket {
        background: url('../images/icon_new_basket_white.svg') no-repeat center;
        background-size: 100%;

        span {
          color: $main-white;
        }
      }

      &__menu {
        &.covered {
          opacity: 0;
          visibility: hidden;
        }

        &__navigation {
          &__list {
            &__item {
              a {
                color: $main-white;
              }
            }
          }
        }
      }

      &__logo {
        &__image {
          background: url('../images/logo_spacestor_white.svg') no-repeat left center;
          background-size: 85%;

          @include media-breakpoint-down(sm) {
            background-size: 100%;
          }
        }
      }

      &__logged-in {
        &__icon {
          background: url('../images/icon-user-white.svg') no-repeat center;
        }
      }
    }

    .hamburger {
      span {
        background-color: $main-white;
      }
    }
  }

  &--hidden {
    top: -100%;
    opacity: 0;
    transition: top .5s ease;
  }
}

.header {
  height: 80px;
  width: 100%;

  @include media-breakpoint-down(sm) {
    padding: 5px 20px;
    height: 74px;
    position: fixed;
    left: 0;
    z-index: 20;
    background-color: $main-white;
    transition: background-color .3s ease;
  }

  &__basket {
    position: relative;
    float: right;
    cursor: pointer;
    display: none;
    height: 32px;
    width: 32px;
    background: url('../images/icon_new_basket.svg') no-repeat center;
    transition: background .3s ease;
    background-size: 80%;
    @include media-breakpoint-down(sm) {
      margin-left: 10px;
    }

    &--menu {
      top: -5px;
      right: 30px;
      padding: 0;
      margin: 0;

      @include media-breakpoint-down(sm) {
        top: -5px;
      }
    }

    span {
      position: absolute;
      right: -10px;
      top: 15px;
      width: 20px;
      height: 20px;
      font-size: 14px;
      color: $main-white;
      text-align: center;
      line-height: 17px;
      background-color: $brand-color;
      border-radius: 50%;
      transition: color .3s ease;
      padding-top: 2px;
    }
  }

  &__logo {
    float: left;
    height: 80px;
    width: 158px;

    @include media-breakpoint-down(sm) {
      width: 90px;
      height: 64px;
    }

    &__image {
      height: 80px;
      width: 180px;
      background: url('../images/logo_spacestor.svg') no-repeat left center;
      background-size: 85%;

      @include media-breakpoint-down(sm) {
        height: 64px;
        width: 94px;
        background-size: 100%;
      }
    }
  }

  &__breadcrumbs {
    display: inline-block;
    height: 20px;
    list-style-type: none;


    &__item {
      float: left;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      margin-left: 30px;

      a {
        position: relative;
        color: $main-black;
        text-decoration: none;
        line-height: 20px;
        cursor: pointer;
        float: left;

        &:before {
          position: relative;
          top: 1px;
          left: -10px;
          display: inline-block;
          width: 12px;
          height: 12px;
          content: '';
          color: $brand-color;
          border-style: solid;
          border-width: 0 0 3px 3px;
          border-radius: 1px;
          transform: rotate(45deg);
        }
      }
    }
  }

  &__dropdown {
    position: relative;
    height: 80px;
    float: right;
    padding: 25px 0;

    @include media-breakpoint-down(sm) {
      height: 64px;
      padding: 22px 0;

    }

    &__inner {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($main-black, 0.5);
      z-index: 22;
      visibility: hidden;
    }

    &__features {
      position: relative;
      display: inline-block;
      width: 100%;
      padding: 0 60px 60px 0;

      @include media-breakpoint-down(sm) {
        padding-bottom: 20px;
      }

      &__close {
        position: relative;
        float: right;
        width: 20px;
        height: 20px;
        z-index: 5;
        cursor: pointer;

        &:before {
          content: ' ';
          position: absolute;
          width: 20px;
          height: 2px;
          background-color: $main-black;
          transform: rotate(135deg);
          transition: .2s linear;
          top: 8px;
          left: 0;
        }

        &:after {
          content: ' ';
          position: absolute;
          width: 20px;
          height: 2px;
          background-color: $main-black;
          transform: rotate(45deg);
          transition: .2s linear;
          top: 8px;
          left: 0;
        }

        &:hover {
          &:before,
          &:after {
            background-color: $brand-color;
          }
        }
      }
    }

    &__content {
      background: $main-white;
      bottom: 0;
      display: flex;
      flex-direction: column;
      padding: 30px 0 30px 55px;
      position: fixed;
      text-align: left;
      top: 0;
      transform: translate3d(100vw, 0, 0);
      transition: .15s linear;
      width: 360px;
      z-index: 160000114;

      @include media-breakpoint-down(sm) {
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
      }

      &.active {
        transform: translate3d(calc(100vw - 360px), 0, 0);

        @include media-breakpoint-down(sm) {
          transform: translate3d(0, 0, 0) !important;
        }
      }

      a {
        text-decoration: none;
      }

      &__item {
        position: relative;

        &__text {
          display: block;
          width: fit-content;
          color: $main-black;
          font-weight: 300;
          font-size: 20px;
          line-height: 24px;
          padding: 8px 16px 8px 8px;
          transition: .2s linear;

          &:hover {
            font-weight: 700;
            padding-left: 0;
            transition: .2s linear;
          }

          &.active {
            font-weight: 700;
          }
        }

        &__bar {
          position: absolute;
          width: 100%;
          height: 8px;
          top: 50%;
          transform: translate3d(100%, -50%, 0);
          transition: .7s cubic-bezier(0.165, 0.84, 0.44, 1);

          &__darken {
            position: absolute;
            top: 0;
            height: 8px;
          }

          &.bar--cyanogen {
            background-color: rgb(0, 105, 143);

            .header__dropdown__content__item__bar__darken {
              background-color: darken(rgb(0, 105, 143), 10%);
            }
          }

          &.bar--red-orange {
            background-color: rgb(242, 89, 41);

            .header__dropdown__content__item__bar__darken {
              background-color: darken(rgb(242, 89, 41), 10%);
            }
          }

          &.bar--pink-red {
            background-color: rgb(229, 102, 107);

            .header__dropdown__content__item__bar__darken {
              background-color: darken(rgb(229, 102, 107), 10%);
            }
          }

          &.bar--red {
            background-color: rgb(240, 64, 36);

            .header__dropdown__content__item__bar__darken {
              background-color: darken(rgb(240, 64, 36), 10%);
            }
          }

          &.bar--brown {
            background-color: rgb(247, 148, 31);

            .header__dropdown__content__item__bar__darken {
              background-color: darken(rgb(247, 148, 31), 10%);
            }
          }

          &.bar--brown-darken {
            background-color: rgb(189, 133, 46);

            .header__dropdown__content__item__bar__darken {
              background-color: darken(rgb(189, 133, 46), 10%);
            }
          }

          &.bar--cyanogen-brighter {
            background-color: rgb(168, 184, 184);

            .header__dropdown__content__item__bar__darken {
              background-color: darken(rgb(168, 184, 184), 10%);
            }
          }

          &.bar--cyanogen-darken {
            background-color: rgb(5, 122, 150);

            .header__dropdown__content__item__bar__darken {
              background-color: darken(rgb(5, 122, 150), 10%);
            }
          }

          &.bar--turquoise {
            background-color: rgb(166, 209, 214);

            .header__dropdown__content__item__bar__darken {
              background-color: darken(rgb(166, 209, 214), 10%);
            }
          }

          &.bar--dark-grey {
            background-color: rgb(79, 74, 71);

            .header__dropdown__content__item__bar__darken {
              background-color: darken(rgb(79, 74, 71), 10%);
            }
          }
        }

        &.active {
          .header__dropdown__content__item__text {
            color: #EEEAEA;
          }
        }
      }

      &__search {
        margin-top: auto;
        margin-right: 55px;

        &__label {
          display: block;
          background: url('../images/icon-search.svg') no-repeat right;
          background-position-x: calc(100% - 10px);
        }

        &__input {
          display: block;
          height: 60px;
          width: 100%;
          padding-right: 40px;
          border: none;
          border-bottom: 1px solid #EEEAEA;
          color: $main-black;
          font-family: $font-family;
          font-size: 15px;
          font-weight: 100;
          line-height: 100%;
          cursor: auto;
          background: transparent;

          &::placeholder {
            font-weight: 100;
            color: $main-black;
          }

          &:focus {
            font-weight: 100;
            border-bottom-color: $brand-color;
          }
        }
      }

      &__shadow {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }
  }

  &__logged-in {
    float: right;
    padding: 30px 0;
    position: relative;

    @include media-breakpoint-down(sm) {
      padding: 22px 0;
      margin-right: 20px;
    }

    &__icon {
      width: 20px;
      height: 20px;
      margin-right: 30px;
      cursor: pointer;
      background: url('../images/icon-user.svg') no-repeat center;
      transition: background-image .4s ease;

      @include media-breakpoint-down(sm) {
        margin-left: 0;
        margin-right: 0;
      }

      &:hover,
      &.active {
        background: url('../images/icon-user-orange.svg') no-repeat center;
      }
    }

    &__dropdown {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      right: 30px;
      top: 80px;
      transition: opacity .4s, visibility .4s ease;

      @include media-breakpoint-down(sm) {
        top: 64px;
        right: 0;
        width: 100vw;
        height: calc(100vh - 64px);
        position: fixed;
        opacity: 1;
        visibility: visible;
        transform: translate3d(100%, 0, 0);
        transition: transform .3s linear;
      }

      &.active {
        opacity: 1;
        visibility: visible;

        @include media-breakpoint-down(sm) {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }

  &__menu {
    float: right;
    height: 100%;
    margin: 0;
    position: relative;
    line-height: 20px;
    opacity: 1;
    visibility: visible;
    transition: opacity .4s, visibility .4s ease;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    &__navigation {
      position: relative;
      height: 100%;

      &__list {
        display: none;
        height: 100%;
        list-style-type: none;
        margin: 0;
        padding: 0;

        &--visible {
          display: flex;
        }

        &__item {
          display: flex;
          margin-right: 35px;
          transition: all .2s linear;
          font-size: 14px;
          font-weight: 500;
          text-transform: capitalize;
          border-bottom: 2px solid transparent;

          &--inside-link,
          a {
            display: flex;
            align-items: center;
            letter-spacing: 0.5px;
            color: $main-black;
            text-decoration: none;
            cursor: pointer;
            transition: all .2s linear;

            &:hover {
              transition: all .2s linear;
              color: $brand-color;
            }
          }

          a {
            @include media-breakpoint-down(md) {
              margin-right: 20px;
            }
          }

          &.active {
            border-color: $brand-color;

            .header__menu__navigation__list__item--inside-link,
            a {
              color: $brand-color;
              transition: .2s linear;
            }
          }
        }
      }
    }
  }
}

.hamburger {
  border: 0;
  height: 30px;
  width: 30px;
  padding: 0;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  outline: none;

  @include media-breakpoint-down(sm) {
    width: 20px;
    height: 20px;
  }

  span {
    display: block;
    height: 2px;
    width: 30px;
    background-color: $main-white;
    border-radius: 2px;
    position: absolute;
    transition: all 300ms ease-in-out;

    &:first-child {
      top: 5px;

      @include media-breakpoint-down(sm) {
        top: 0;
      }
    }

    &:nth-child(2) {
      top: 14px;

      @include media-breakpoint-down(sm) {
        top: 8px;
      }
    }

    &:last-child {
      top: 23px;

      @include media-breakpoint-down(sm) {
        top: 16px;
      }
    }
  }

  &--transparent {
    background-color: transparent;

    &:hover {
      span {
        background-color: $brand-color;
      }
    }

    span {
      background-color: $main-black;
    }
  }
}

.hamburger-transition {
  -webkit-transition: -webkit-transform 0.3s;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

// Mobile header in product page details
.header {
  &__menu-overlay {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    background: rgba($main-black, 0.25);
  }

  &__menu-tab-container {
    position: relative;
    background: $main-white;
  }

  &__menu-tab {
    display: flex;
    align-items: center;
  }

  &__menu-tab-text {
    font-size: 11px;
    font-weight: 600;
    line-height: 14px;
    color: $main-black;
    padding: 7px 26px 12px;
    border-left: 2px solid transparent;
    cursor: pointer;
    transition: .3s ease;

    &.active {
      color: $brand-color;
      border-color: $brand-color;
      transition: .3s ease;

      & + .header__menu-tab-arrow {
        background: url('../images/icon-toggle-down-primary.svg') center/contain no-repeat;
      }
    }
  }

  &__menu-tab-arrow {
    display: block;
    width: 14px;
    height: 14px;
    margin-bottom: 5px;
    background: url('../images/icon-toggle-down.svg') center/contain no-repeat;
    transform: rotate(0);
    transition: .3s ease;
  }

  &__menu-list {
    display: none;
    padding: 0 0 7px;
  }

  &__menu-item {
    font-size: 11px;
    font-weight: 600;
    line-height: 14px;
    color: $main-black;
    padding: 7px 26px;
    border-left: 2px solid transparent;
    cursor: pointer;
    transition: .3s ease;

    &.active {
      color: $brand-color;
      border-color: $brand-color;
      transition: .3s ease;
    }
  }

  &__menu {
    &--mobile {
      position: absolute;
      top: 74px;
      left: 0;
      display: none;
      width: 100%;

      &.header__menu--visible {
        @include media-breakpoint-down(sm) {
          display: block;
        }
      }

      &.active {
        height: calc(100vh - 74px);

        .header {
          &__menu-overlay {
            opacity: 1;
            visibility: visible;
            transition: .3s ease;
          }

          &__menu-tab-arrow {
            transform: rotate(180deg);
            transition: .3s ease;
          }
        }
      }
    }
  }
}
