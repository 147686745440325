@import "responsive-bootstrap";

$font-family: 'museo-sans', sans-serif;
$main-black: #1c1417;
$main-white: #FFFFFF;
$main-gray: #F5F5F5;
$main-gray-darker: #DCDCDC;
$main-brown: #4F4A47;
$dark-grey: #919191;
$brand-color: #DB3645;
$brand-color-brighter: rgba($brand-color, .15);

$navigation-black: #000;
$navigation-grey: #EEE;

$grey: #B3B3B3;
$grey-platinum: #C7CBC4;
$grey-dark: #e3e3e3;

$grey-light: #DDDDDD;

$border-grey-color: #D6D2D2;

$bar--turquoise: #A6D3D8;
$bar--dark-turquoise: #6EB9BE;

$bar--blue: rgba(110, 185, 189, 0.6);
$bar--dark-blue: #6eb9bd;

$bar--orange: #f6931d;
$bar--dark-orange: #e76923;

$bar--pink: #ff686d;
$bar--dark-pink: #ed022a;

$bar--gray: #a7b8b7;
$bar--dark-gray: #7a9190;

$bar--red: #f05829;
$bar--dark-red: #ee2a23;

$bar--green: #0C7C98;
$bar--dark-green: #085B6D;


$bar--brown: #BD852E;
$bar--dark-brown: #8F5217;

$alert--error: rgb(253, 237, 237);
$alert--error-dark: rgb(95, 33, 32);

$black: #1c1417;
$brown: #4F4A47;
$grey: #DCDCDC;
$grey-2: #F5F5F5;
$grey-3: #F3F3F3;
$grey-darker: #B3B3B3;
$grey-lighter: #EEEAEA;
$red: #DB3645;
$red-bright: #FF686D;
$white: #FFFFFF;

$moduleSpace: 100px;

@mixin title($color: $white, $size: 56, $weight: 600) {
  color: $color;
  font-size: #{$size}px;
  font-weight: $weight;
  line-height: #{$size + 6}px;

  @include media-breakpoint-down(sm) {
    $mobileSize: $size * 1.875 / 3;

    font-size: #{$mobileSize}px;
    line-height: #{$mobileSize + 2}px;
  }
}

@mixin text($color: $black, $size: 18, $lineHeight: 18, $weight: 400) {
  color: $color;
  font-size: #{$size}px;
  font-weight: $weight;

  @if $lineHeight {
    line-height: #{$lineHeight}px;
  } @else {
    line-height: #{$size * 5 / 3}px;
  }
}

@mixin img($fit, $position: center) {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: $fit;
  object-position: $position;
}

@mixin btn-brand-color {
  display: inline-block;
  border-radius: 6px;
  border: none;
  background-color: $brand-color;
  padding: 8px 5px;
  color: $main-white;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
}

@mixin input-default {
  display: block;
  padding: 10px;
  font-weight: 500;
  font-size: 14px;
  color: $main-black;
  letter-spacing: 0.4px;
  line-height: 17px;
  overflow: hidden;
  word-wrap: break-word;
  border-radius: 6px;
  border: 1px solid #D6D2D2;
}

@mixin close-button {
  background: transparent;
  border: none;
  cursor: pointer;
  height: 20px;
  outline: none;
  padding: 0;
  position: relative;
  width: 20px;

  &:before,
  &:after {
    left: 50%;
    position: absolute;
    content: '';
    top: 50%;
    width: 20px;
    height: 2px;
    background-color: $main-black;
  }

  &:before {
    transform: translate(-50%,-50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%,-50%) rotate(-45deg);
  }
}

@mixin scrollbar($thumb-color, $track-color, $width, $radius) {
  //scrollbar-color: $thumb-color $track-color !important;
  //scrollbar-width: thin !important;

  &::-webkit-scrollbar {
    width: $width;
    height: $width;
  }

  &::-webkit-scrollbar-track {
    background-color: $track-color;
    border-radius: $radius;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumb-color;
    border-radius: $radius;
  }
}

@mixin video-panel {
  &__replay {
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-image .3s ease;
    background-image: url(../images/icon-replay-white.svg);
  }

  &__play {
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-image .3s ease;
    background-image: url(../images/icon-play-white.svg);

    &.disabled {
      background-image: url(../images/icon-pause-white.svg);
    }
  }

  &__sound {
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-image: url(../images/icon-sound-white.svg);
    background-repeat: no-repeat;
    background-position: center;
    transition: background-image .3s ease;

    &.disabled {
      background-image: url(../images/icon-mute-white.svg);
    }
  }

  &__volume {
    -webkit-appearance: none;
    background: transparent;
    margin: 10px 0;
    width: 150px !important;
    padding: 0 20px;
    cursor: pointer;
  }

  &__full-screen {
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-image: url(../images/icon-full-size.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
}

@mixin custom-button($background: $red, $padding: 8px 5px, $border-radius: 6px, $color: $white, $text-transform: uppercase) {
  background: $background;
  border-color: $red;
  border-radius: $border-radius;
  border-style: solid;
  color: $color;
  cursor: pointer;
  display: block;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 14px;
  padding: $padding;
  text-align: center;
  text-decoration: none;
  text-transform: $text-transform;
  width: fit-content;
}
